import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "title", "data-ga-item_id"]

import { computed } from 'vue';

interface Props {
	label: string,
	url: string,
	title: string,
	filled: boolean
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Pill',
  props: {
    label: {},
    url: {},
    title: {},
    filled: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const pillTitle = computed(() => (props.title ? props.title : props.label));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["pill", {'pill--filled': _ctx.filled}]),
    href: _ctx.url,
    title: pillTitle.value,
    "data-ga-event": "select_content",
    "data-ga-content_type": "Quickpill",
    "data-ga-item_id": pillTitle.value
  }, _toDisplayString(_ctx.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
}
}

})