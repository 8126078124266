<template>
	<div class="rpb_trustpilot">
		<h3 class="rpb_trustpilot__header">
			{{ headerText() }}
		</h3>
		<div
			v-if="totalStars"
			class="rpb_trustpilot__summary"
		>
			<strong>{{ determineRatingString(total.stars) }}</strong><span
				class="rpb_trustpilot__rating rpb_trustpilot__rating--medium rpb_lazy"
				:data-bg="`${getBaseUrl()}/fileadmin/2/all/img/trustpilot/star_${totalStars}.svg`"
			></span>
			<p>Basierend auf {{ total.reviews }} Bewertungen. Einige unserer Bewertungen.</p>
		</div>
		<div class="rpb_trustpilot__reviews">
			<div
				v-for="(review, index) in reviews"
				:key="index"
				class="rpb_trustpilot-review"
			>
				<div class="rpb_trustpilot-review__stars">
					<span
						class="rpb_trustpilot__rating rpb_trustpilot__rating--small rpb_lazy"
						:data-bg="`${getBaseUrl()}/fileadmin/2/all/img/trustpilot/star_${review.stars * 10}.svg`"
					></span>
				</div>
				<div class="rpb_trustpilot-review__text">
					{{ review.text }}
				</div>
				<div class="rpb_trustpilot-review__reviewer">
					<span>{{ review.name }}</span> <em>{{ relativeTime(review.created) }}</em>
				</div>
			</div>
		</div>
		<div class="rpb_trustpilot__footer">
			<a
				class="rpb_trustpilot__button"
				:href="trustpilotUrl"
				target="_blank"
				rel="noopener"
			>
				<span>Alle Bewertungen auf</span><img
					class="rpb_lazy"
					:src="`data:image/gif;base64,R0lGODdhAQABAPAAAP///wAAACwAAAAAAQABAEACAkQBADs=`"
					:data-src="`${getBaseUrl()}/fileadmin/2/all/img/trustpilot/logo_130x32.png`"
					alt="trustpilot"
					width="120"
					height="30"
				/>
			</a>
		</div>
	</div>
</template>

<script lang="ts" setup>
import type { ClientType, TrustpilotReview } from '@/interfaces/common';
import {
	determineRatingString, relativeTime,
} from '@utils/utils';
import { getBaseUrl } from '@utils/environmentUtils';
import { computed } from 'vue';
import { WEB_CLIENTS_NAMES } from '@global-js/constants';

export interface Total {
	reviews: number,
	stars: number,
}

interface Props {
	trustpilotUrl: string,
	client: ClientType,
	reviews: TrustpilotReview[],
	total: Total
}

const props = defineProps<Props>();

const totalStars = computed((): number => props.total.stars * 10);

const headerText = (): string => {
	const base = 'Top Beratung und sichere Buchung - so bewerten unsere Kunden';
	switch (props.client) {
	case 'kuat':
		return `${base} ${WEB_CLIENTS_NAMES.kuat}.at`;
	case 'lmat':
		return `${base} ${WEB_CLIENTS_NAMES.lmat}.at`;
	case 'at':
	case 'com':
	case 'ch':
		return `${base} die Restplatzbörse`;
	case 'tbde':
		return `${base} ${WEB_CLIENTS_NAMES.tbde}.de`;
	default:
		return base;
	}
};

</script>

<style lang="scss" scoped>

.rpb_trustpilot {
	margin: 0 auto $vgrid-mobile-large;
	line-height: 1.42857;

	&__rating {
		display: inline-block;
		background-size: cover;
		height: 2.4rem;
		width: 13rem;
		vertical-align: middle;

		&--medium {
			height: 1.8rem;
			width: 9.8rem;
		}

		&--small {
			height: 1.5rem;
			width: 8.1rem;
		}
	}

	&__summary {
		text-align: center;
		padding: 3rem 1.5rem;

		strong {
			font-weight: normal;
			font-size: 2.1em;
			vertical-align: middle;
			display: inline-block;
			margin-right: 1rem;
		}

		p {
			font-size: 1.3em;
			color: $color-text-l3;
			margin-top: 0.5rem;
			margin-bottom: 0;
		}
	}

	&__reviews {
		border-top: 0.1rem solid $color-primary-l5;
		border-bottom: 0.1rem solid $color-primary-l5;
		display: flex;
		flex-direction: column;
	}

	&__header {
		@include lineHeightCrop(1.4);

		padding-left: 0.3rem;
		padding-right: 0.3rem;
		padding-top: 0;
	}

	&__footer {
		text-align: center;
		padding-top: 3rem;
	}

	&__button {
		display: inline-block;
		border: 0.1rem solid $color-primary-l5;
		border-radius: $border-radius-xx-large;
		line-height: 1;
		font-weight: $font-weight-bold;
		font-size: 1.1em;
		padding: 1.3rem 3rem 1.5rem;
		color: $color-dark-gray;
		cursor: pointer;
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			color: $color-dark-gray;
		}

		span {
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
		}

		img {
			display: inline-block;
			margin-left: 1rem;
			height: 1.9rem;
			width: auto;
			vertical-align: middle;
			position: relative;
			top: -0.3rem;
		}
	}

	&__text {
		overflow: hidden;
		height: 5.1em;
	}
}

.rpb_trustpilot-review {
	padding: 3rem 1.5rem;
	border-top: 0.1rem solid $color-primary-l5;
	width: 100%;
	display: inline-block;

	&:first-child {
		border-top: 0;
	}

	&:last-child {
		border-right: 0;
	}

	&__stars {
		padding-bottom: 2.5rem;
	}

	&__text {
		font-style: italic;
		font-size: 1.3em;
		line-height: 1.7em;
		font-weight: $font-weight-light;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&__reviewer {
		padding-top: 1.5rem;
		font-size: 1.2em;

		span {
			display: inline-block;
			margin-right: 1.5rem;
		}

		em {
			display: inline-block;
			font-style: normal;
			color: $color-text-t7;
		}
	}
}

@media (min-width: $breakpoint-verysmall) {
	.rpb_trustpilot__summary {
		padding-bottom: 4rem;
	}

	.rpb_trustpilot__footer {
		padding-top: 4rem;
	}

	.rpb_trustpilot__text {
		overflow: hidden;
		height: 1.7em * 3;
	}

	.rpb_trustpilot__button {
		font-size: 1.2em;
		padding: 1.8rem 3.5rem 1.9rem;

		img {
			height: 2.5rem;
			top: -0.4rem;
		}
	}

	.rpb_trustpilot__reviews {
		flex-direction: row;
	}

	.rpb_trustpilot-review {
		font-size: 0.9em;
		border-top: 0;
		margin: 2rem 0;
		padding: 1.5rem 3rem 1.5rem 4rem;
		width: 49%;
		display: inline-block;
		border-right: 0.1rem solid $color-primary-l5;

		&:last-child {
			border-right: 0;
		}
	}
}

@media (min-width: $breakpoint-small) {
	.rpb_trustpilot {
		margin-bottom: $vgrid-desktop-large;
	}

	.rpb_trustpilot__summary {
		padding-bottom: 5rem;

		strong {
			font-size: 2em;
		}

		p {
			font-size: 1.2em;
		}
	}

	.rpb_trustpilot__footer {
		padding-top: 5rem;
	}

	.rpb_trustpilot-review {
		width: 50%;

		&:last-child {
			border-right: 0;
		}
	}
}

@media (min-width: $breakpoint-medium) {
	.rpb_trustpilot-review {
		width: 33%;

		&:nth-child(3) {
			display: inline-block;
			border-right: none;
		}
	}
}

</style>
