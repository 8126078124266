<template>
	<Newsletter
		:loading="loading"
		:type-of-error="typeOfError"
		:subscription-state="subscriptionState"
		:client="client"
		@newsletter:subscribe="subscribeNewsletter"
		@newsletter:openPrivacy="openPrivacy"
		@newsletter:resetState="resetState"
	/>
</template>

<script lang="ts" setup>
import { determineClient } from '@utils/environmentUtils';
import Newsletter from '@lmt-rpb/Newsletter/Newsletter.vue';
import { ref } from 'vue';

const client = determineClient(document.location.href);

const loading = ref(false);

const typeOfError = ref('');

const subscriptionState = ref('start'); // possible values: start, success & error.

const openPrivacy = (): void => {
	if (window.UC_UI) {
		window.UC_UI.showSecondLayer('o5fE3ybZ'); // Details öffnen
	}
};

const resetState = (): void => {
	loading.value = false;
	typeOfError.value = '';
	subscriptionState.value = 'start';
};

const handleSuccess = (): void => {
	loading.value = false;
	if (window.UC_UI) {
		window.UC_UI.acceptService('o5fE3ybZ');
	}
	subscriptionState.value = 'success';
};

const handleError = (error: any): void => {
	loading.value = false;
	subscriptionState.value = 'error';
	if (error === 'invalid address') {
		typeOfError.value = 'invalid';
	} else if (error === 'duplicate address') {
		typeOfError.value = 'duplicate';
	} else {
		typeOfError.value = 'unknown';
		console.error('error:', error);
	}
};

const callbackHandler = (response: string): boolean => {
	if (response === 'invalid address' || response === 'duplicate address') {
		handleError(response);
		return false;
	}
	if (response === '1') {
		handleSuccess();
		return true;
	}
	handleError(response);
	return false;
};

const ajaxPost = (url: string, params: string, callback: (response: string) => void): void => {
	const xmlhttp = new XMLHttpRequest();
	xmlhttp.onreadystatechange = () => {
		let data = null;

		if (xmlhttp.readyState === 4) {
			if (xmlhttp.status === 200) {
				try {
					data = xmlhttp.responseText;
				} catch (err: any) {
					// eslint-disable-next-line no-console
					console.log(err.message + ' in ' + xmlhttp.responseText);
					handleError(err.message);
					return;
				}
				callback(data);
			} else {
				// eslint-disable-next-line no-console
				console.error('Fehler beim Anmelden zum Newsletter - Response Code: ' + xmlhttp.status);
				handleError(xmlhttp.status);
			}
		}
	};

	xmlhttp.open('POST', url, true);
	xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
	xmlhttp.send(params);
};

const subscribeNewsletter = (email: string): void => {
	const params = 'email=' + encodeURIComponent(email);
	loading.value = true;
	ajaxPost('?no_cache=1&type=11000', params, callbackHandler);
};

</script>

<style lang="scss" scoped>
</style>
