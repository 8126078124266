/* eslint-disable import/extensions */
import { getFluxImageUrl } from '@utils/utils';
import { getBaseUrl } from '@utils/environmentUtils';
import { HotelBoxData } from '@interfaces/components/hotelBoxData';
import { Board } from '@/js/data/board-types';
import { Deal } from '@/interfaces/api/v2-deals';
import { HotelDeal } from '@/interfaces/api/v1-hotel-deals';

const fluxImageUrl = getFluxImageUrl();
const baseUrl = getBaseUrl();

const transformToV2Deals = (offer: Deal): HotelBoxData => ({
	Name: offer.hotel_name,
	HotelIffCode: offer.iff_code,
	Url: offer.dealUrl,
	CityName: offer.destination,
	RegionName: offer.top_region,
	CountryName: offer.country,
	Category: Number(offer.stars),
	MeanRatingOverall: offer.rating_overall,
	Price: offer.price,
	TravelDuration: offer.travel_duration,
	BoardType: offer.room_meal,
	DepartureAirportTown: offer.departure_airport_town,
	DepartureDay: offer.departure_day,
	DealFactor: offer.deal_factor,
	Discount: offer.discount,
	PriceAverage: offer.price_avg,
	Image: {
		l: offer.image.urls.l,
		m: offer.image.urls.m,
		s: offer.image.urls.s,
		xs: offer.image.urls.xs,
	}
});

const transformToV1HotelDeals = (offer: HotelDeal): HotelBoxData => {
	const tomorrow = '1_days';
	const returnDate = (30 + offer.PackageOffer.BetweenDeparturesDuration) + '_days';
	const params = `?ddate=${tomorrow}
		&rdate=${returnDate}
		&dur=${offer.PackageOffer.BetweenDeparturesDuration}
		&board=3`;

	return {
		Name: offer.Hotel.Name,
		HotelIffCode: offer.Hotel.IffCode,
		Url: baseUrl + offer.Hotel.URL + params,
		CityName: offer.Location.City.CityName,
		RegionName: offer.Location.Region.RegionName,
		CountryName: offer.Location.Country.CountryName,
		Category: offer.Hotel.Category,
		MeanRatingOverall: offer.Ratings.Overall,
		Price: offer.PackageOffer.Price,
		TravelDuration: offer.PackageOffer.BetweenDeparturesDuration,
		BoardType: Board[offer.PackageOffer.Board],
		DepartureAirportTown: offer.PackageOffer.Flight.OutboundFlight.AirportName,
		DepartureDay: offer.PackageOffer.StartDate,
		DealFactor: 100 + offer.PackageOffer.Discount,
		Discount: offer.PackageOffer.Discount,
		PriceAverage: offer.PackageOffer.HighPrice,
		Image: {
			l: `${fluxImageUrl}/hotel/${offer.Hotel.IffCode}/1/l`,
			m: `${fluxImageUrl}/hotel/${offer.Hotel.IffCode}/1/m`,
			s: `${fluxImageUrl}/hotel/${offer.Hotel.IffCode}/1/s`,
			xs: `${fluxImageUrl}/hotel/${offer.Hotel.IffCode}/1/xs`,
		}
	};
};

export const transformList = (hotelList: Deal[] | HotelDeal[], type: 'v1-hotel-deals' | 'v2-deals'): HotelBoxData[] => {
	let data: HotelBoxData[];
	if (type === 'v1-hotel-deals') {
		data = hotelList.map((hotel) => transformToV1HotelDeals(hotel as HotelDeal));
	} else {
		data = hotelList.map((hotel) => transformToV2Deals(hotel as Deal));
	}
	return data;
};
