import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "favorited-hotel-list__wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "favorited-hotel-list__no-items"
}

import FavoritedHotelItem from '@lmt-rpb/FavoritedHotelItem/FavoritedHotelItem.vue';
import { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';

interface Props {
	hotels: FavoritedHotel[];
	showSuns: boolean,
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FavoritedHotelList',
  props: {
    hotels: {},
    showSuns: { type: Boolean, default: false }
  },
  emits: ['FavoritedHotelList:Delete'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;



const onDelete = (hotel: FavoritedHotel): void => {
	emit('FavoritedHotelList:Delete', hotel);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.hotels.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_TransitionGroup, {
            name: "list",
            tag: "ul",
            class: "favorited-hotel-list__listing"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotels, (h) => {
                return (_openBlock(), _createBlock(FavoritedHotelItem, {
                  key: h.IffCode,
                  "iff-code": h.IffCode,
                  title: h.Name,
                  url: '/hotel/' + h.IffCode ,
                  region: h.Region,
                  "hotel-rating": h.Category,
                  "show-suns": _ctx.showSuns,
                  "onFavoritedHotelItem:Delete": ($event: any) => (onDelete(h))
                }, null, 8 /* PROPS */, ["iff-code", "title", "url", "region", "hotel-rating", "show-suns", "onFavoritedHotelItem:Delete"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, " Sie haben noch nichts in dem Merkzettel gespeichert. "))
  ]))
}
}

})