import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "rpb_trustpilot" }
const _hoisted_2 = { class: "rpb_trustpilot__header" }
const _hoisted_3 = {
  key: 0,
  class: "rpb_trustpilot__summary"
}
const _hoisted_4 = ["data-bg"]
const _hoisted_5 = { class: "rpb_trustpilot__reviews" }
const _hoisted_6 = { class: "rpb_trustpilot-review__stars" }
const _hoisted_7 = ["data-bg"]
const _hoisted_8 = { class: "rpb_trustpilot-review__text" }
const _hoisted_9 = { class: "rpb_trustpilot-review__reviewer" }
const _hoisted_10 = { class: "rpb_trustpilot__footer" }
const _hoisted_11 = ["href"]
const _hoisted_12 = ["data-src"]

import type { ClientType, TrustpilotReview } from '@/interfaces/common';
import {
	determineRatingString, relativeTime,
} from '@utils/utils';
import { getBaseUrl } from '@utils/environmentUtils';
import { computed } from 'vue';
import { WEB_CLIENTS_NAMES } from '@global-js/constants';

export interface Total {
	reviews: number,
	stars: number,
}

interface Props {
	trustpilotUrl: string,
	client: ClientType,
	reviews: TrustpilotReview[],
	total: Total
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Trustpilot',
  props: {
    trustpilotUrl: {},
    client: {},
    reviews: {},
    total: {}
  },
  setup(__props: any) {

const props = __props;

const totalStars = computed((): number => props.total.stars * 10);

const headerText = (): string => {
	const base = 'Top Beratung und sichere Buchung - so bewerten unsere Kunden';
	switch (props.client) {
	case 'kuat':
		return `${base} ${WEB_CLIENTS_NAMES.kuat}.at`;
	case 'lmat':
		return `${base} ${WEB_CLIENTS_NAMES.lmat}.at`;
	case 'at':
	case 'com':
	case 'ch':
		return `${base} die Restplatzbörse`;
	case 'tbde':
		return `${base} ${WEB_CLIENTS_NAMES.tbde}.de`;
	default:
		return base;
	}
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(headerText()), 1 /* TEXT */),
    (totalStars.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("strong", null, _toDisplayString(_unref(determineRatingString)(_ctx.total.stars)), 1 /* TEXT */),
          _createElementVNode("span", {
            class: "rpb_trustpilot__rating rpb_trustpilot__rating--medium rpb_lazy",
            "data-bg": `${_unref(getBaseUrl)()}/fileadmin/2/all/img/trustpilot/star_${totalStars.value}.svg`
          }, null, 8 /* PROPS */, _hoisted_4),
          _createElementVNode("p", null, "Basierend auf " + _toDisplayString(_ctx.total.reviews) + " Bewertungen. Einige unserer Bewertungen.", 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviews, (review, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "rpb_trustpilot-review"
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", {
              class: "rpb_trustpilot__rating rpb_trustpilot__rating--small rpb_lazy",
              "data-bg": `${_unref(getBaseUrl)()}/fileadmin/2/all/img/trustpilot/star_${review.stars * 10}.svg`
            }, null, 8 /* PROPS */, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, _toDisplayString(review.text), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", null, _toDisplayString(review.name), 1 /* TEXT */),
            _cache[0] || (_cache[0] = _createTextVNode()),
            _createElementVNode("em", null, _toDisplayString(_unref(relativeTime)(review.created)), 1 /* TEXT */)
          ])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("a", {
        class: "rpb_trustpilot__button",
        href: _ctx.trustpilotUrl,
        target: "_blank",
        rel: "noopener"
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("span", null, "Alle Bewertungen auf", -1 /* HOISTED */)),
        _createElementVNode("img", {
          class: "rpb_lazy",
          src: `data:image/gif;base64,R0lGODdhAQABAPAAAP///wAAACwAAAAAAQABAEACAkQBADs=`,
          "data-src": `${_unref(getBaseUrl)()}/fileadmin/2/all/img/trustpilot/logo_130x32.png`,
          alt: "trustpilot",
          width: "120",
          height: "30"
        }, null, 8 /* PROPS */, _hoisted_12)
      ], 8 /* PROPS */, _hoisted_11)
    ])
  ]))
}
}

})