import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "rpb_newsletter__formfield" }
const _hoisted_2 = {
  key: 0,
  class: "rpb_newsletter__subscribe rpb_newsletter--active"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["aria-checked"]
const _hoisted_5 = { class: "checkbox__label" }
const _hoisted_6 = { class: "icon rpb_envelope" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "rpb_newsletter__thankyou rpb_newsletter--active"
}
const _hoisted_9 = {
  key: 2,
  class: "rpb_newsletter__error rpb_newsletter--active"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["href"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { getBasePath, getBaseUrl } from '@utils/environmentUtils';
import { computed, onMounted, ref } from 'vue';
import { ClientType } from '@/interfaces/common';

interface Props {
	loading: boolean,
	typeOfError: string,
	subscriptionState: string,
	client?: ClientType,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Newsletter',
  props: {
    loading: { type: Boolean },
    typeOfError: {},
    subscriptionState: {},
    client: { default: 'at' }
  },
  emits: ['newsletter:resetState', 'newsletter:openPrivacy', 'newsletter:subscribe'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const checkboxModel = ref(false);

const invalidEmail = ref(false);

const invalidCheckbox = ref(false);

const show = ref(true);

const baseUrl = getBaseUrl();

const basePath = getBasePath();

const email = ref('');

const background = ref('background-image: url("' + baseUrl + '/fileadmin/2/restplatzboerse/all/img/footer/back.jpg");');

const marketingEmail = computed(() : string => {
	if (props.client === 'kuat') {
		return 'barbara.winter@kuoni.at';
	}
	if (props.client === 'ch') {
		return 'marketing@restplatzboerse.ch';
	}
	if (props.client === 'com') {
		return 'marketing@restplatzboerse.com';
	}
	return 'marketing@restplatzboerse.at';
});

const resetState = (): void => {
	email.value = '';
	checkboxModel.value = false;
	emit('newsletter:resetState', email.value);
};

const openPrivacy = (): void => {
	emit('newsletter:openPrivacy', email.value);
};
const closeNewsletter = (): void => {
	show.value = false;
};

const onEscapeKeyUp = (event: KeyboardEvent): void => {
	if (event.key === 'Escape') {
		window.removeEventListener('keyup', onEscapeKeyUp);
		closeNewsletter();
	}
};

const checkForm = (): void => {
	invalidEmail.value = !email.value;
	invalidCheckbox.value = !checkboxModel.value;

	if (email.value && checkboxModel.value) {
		emit('newsletter:subscribe', email.value);
	}
};

onMounted(() => {
	window.addEventListener('keyup', onEscapeKeyUp);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({rpb_newsletter: true, 'rpb_newsletter--fadein rpb_lazy': show.value}),
    style: _normalizeStyle(background.value)
  }, [
    _createElementVNode("span", {
      class: "rpb_close-icon",
      onClick: closeNewsletter
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.subscriptionState === 'start')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: `${_unref(basePath)}/img/footer/envelope.svg`,
              class: _normalizeClass(["rpb_envelope",  { 'rpb_newsletter--fadeout': _ctx.loading }]),
              alt: "Envelope"
            }, null, 10 /* CLASS, PROPS */, _hoisted_3),
            _cache[5] || (_cache[5] = _createElementVNode("h3", null, "NEWSLETTER", -1 /* HOISTED */)),
            _cache[6] || (_cache[6] = _createElementVNode("h4", null, "Newsletter abonnieren und nie wieder ein Top-Angebot verpassen!", -1 /* HOISTED */)),
            _createElementVNode("form", {
              novalidate: "",
              class: _normalizeClass({invalidCheckbox: invalidCheckbox.value, invalidEmail: invalidEmail.value}),
              onSubmit: _withModifiers(checkForm, ["prevent"])
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("label", {
                  class: _normalizeClass(["checkbox", {'checkbox--checked': checkboxModel.value}])
                }, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checkboxModel).value = $event)),
                    value: "privacy_accepted",
                    type: "checkbox",
                    class: "checkbox__input",
                    role: "checkbox",
                    "aria-checked": checkboxModel.value,
                    "aria-label": "Ich habe die Informationen zum Datenschutz gelesen und akzeptiert.",
                    required: ""
                  }, null, 8 /* PROPS */, _hoisted_4), [
                    [_vModelCheckbox, checkboxModel.value]
                  ]),
                  _createElementVNode("span", _hoisted_5, [
                    _createVNode(BaseIcon, {
                      class: "checkbox__icon",
                      name: "check"
                    }),
                    _cache[3] || (_cache[3] = _createTextVNode(" Ich habe die ")),
                    _createElementVNode("a", {
                      onClick: _withModifiers(openPrivacy, ["prevent"])
                    }, [
                      _cache[2] || (_cache[2] = _createTextVNode(" Informationen zum Datenschutz ")),
                      _createVNode(BaseIcon, {
                        name: "newTab",
                        width: "12",
                        height: "12"
                      })
                    ]),
                    _cache[4] || (_cache[4] = _createTextVNode(" gelesen und akzeptiert. "))
                  ])
                ], 2 /* CLASS */)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["rpb_newsletter__form", {invalidEmail: invalidEmail.value}])
              }, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((email).value = $event)),
                  type: "email",
                  name: "email",
                  class: "formInput input",
                  required: "",
                  placeholder: "E-Mail Adresse"
                }, null, 512 /* NEED_PATCH */), [
                  [
                    _vModelText,
                    email.value,
                    void 0,
                    {
                      trim: true,
                      lazy: true
                    }
                  ]
                ]),
                _createElementVNode("button", {
                  class: _normalizeClass(["rpb_btn--primary", {'button-disabled': !checkboxModel.value}]),
                  type: "submit"
                }, " Abonnieren ", 2 /* CLASS */)
              ], 2 /* CLASS */)
            ], 34 /* CLASS, NEED_HYDRATION */)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", {
        class: _normalizeClass(["rpb_newsletter__sending", { 'rpb_newsletter--active': _ctx.loading }])
      }, [
        _createElementVNode("span", _hoisted_6, [
          _createElementVNode("img", {
            src: `${_unref(basePath)}/img/footer/envelope.svg`,
            class: "rpb_envelope",
            alt: "Envelope"
          }, null, 8 /* PROPS */, _hoisted_7)
        ])
      ], 2 /* CLASS */),
      (_ctx.subscriptionState === 'success')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[7] || (_cache[7] = [
            _createElementVNode("h3", null, "Sie haben Post!", -1 /* HOISTED */),
            _createElementVNode("h4", null, "Um die Anmeldung erfolgreich abzuschließen, klicken Sie bitte auf den Link in der E-Mail, die Sie gerade erhalten haben.", -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true),
      (_ctx.subscriptionState === 'error')
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _cache[12] || (_cache[12] = _createElementVNode("h3", null, "Hoppla ...", -1 /* HOISTED */)),
            (_ctx.typeOfError === 'duplicate')
              ? (_openBlock(), _createElementBlock("h4", _hoisted_10, [
                  _cache[8] || (_cache[8] = _createTextVNode(" Scheinbar sind Sie bereits für den Newsletter angemeldet. Falls nicht, schreiben Sie uns gerne ein Mail an ")),
                  _createElementVNode("a", {
                    href: `mailto:${marketingEmail.value}`
                  }, _toDisplayString(marketingEmail.value), 9 /* TEXT, PROPS */, _hoisted_11),
                  _cache[9] || (_cache[9] = _createTextVNode(", wir kümmern uns darum! ")),
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _withModifiers(resetState, ["prevent"])
                  }, "Mit anderer Mailadresse versuchen.")
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.typeOfError === 'invalid')
              ? (_openBlock(), _createElementBlock("h4", _hoisted_12, [
                  _cache[10] || (_cache[10] = _createTextVNode(" Das ist keine gültige E-Mail Adresse. ")),
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _withModifiers(resetState, ["prevent"])
                  }, "Bitte versuchen Sie es noch einmal.")
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.typeOfError === 'unknown')
              ? (_openBlock(), _createElementBlock("h4", _hoisted_13, [
                  _cache[11] || (_cache[11] = _createTextVNode(" Ein unbekannter Fehler ist aufgetreten. ")),
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _withModifiers(resetState, ["prevent"])
                  }, "Bitte versuchen Sie es später noch einmal.")
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 6 /* CLASS, STYLE */))
}
}

})