<template>
	<div
		:class="{rpb_newsletter: true, 'rpb_newsletter--fadein rpb_lazy': show}"
		:style="background"
	>
		<span
			class="rpb_close-icon"
			@click="closeNewsletter"
		></span>
		<div class="rpb_newsletter__formfield">
			<div
				v-if="subscriptionState === 'start'"
				class="rpb_newsletter__subscribe rpb_newsletter--active"
			>
				<img
					:src="`${basePath}/img/footer/envelope.svg`"
					class="rpb_envelope"
					:class=" { 'rpb_newsletter--fadeout': loading }"
					alt="Envelope"
				/>
				<h3>NEWSLETTER</h3>
				<h4>Newsletter abonnieren und nie wieder ein Top-Angebot verpassen!</h4>
				<form
					novalidate
					:class="{invalidCheckbox: invalidCheckbox, invalidEmail: invalidEmail}"
					@submit.prevent="checkForm"
				>
					<div>
						<label
							class="checkbox"
							:class="{'checkbox--checked': checkboxModel}"
						>
							<input
								v-model="checkboxModel"
								value="privacy_accepted"
								type="checkbox"
								class="checkbox__input"
								role="checkbox"
								:aria-checked="checkboxModel"
								aria-label="Ich habe die Informationen zum Datenschutz gelesen und akzeptiert."
								required
							/>
							<span class="checkbox__label">
								<BaseIcon
									class="checkbox__icon"
									name="check"
								/> Ich habe die <a @click.prevent="openPrivacy"> Informationen zum Datenschutz <BaseIcon
									name="newTab"
									width="12"
									height="12"
								/></a> gelesen und akzeptiert.
							</span>
						</label>
					</div>
					<div
						class="rpb_newsletter__form"
						:class="{invalidEmail: invalidEmail}"
					>
						<input
							v-model.trim.lazy="email"
							type="email"
							name="email"
							class="formInput input"
							required
							placeholder="E-Mail Adresse"
						/>
						<button
							class="rpb_btn--primary"
							:class="{'button-disabled': !checkboxModel}"
							type="submit"
						>
							Abonnieren
						</button>
					</div>
				</form>
			</div>
			<div
				class="rpb_newsletter__sending"
				:class="{ 'rpb_newsletter--active': loading }"
			>
				<span class="icon rpb_envelope">
					<img
						:src="`${basePath}/img/footer/envelope.svg`"
						class="rpb_envelope"
						alt="Envelope"
					/>
				</span>
			</div>
			<div
				v-if="subscriptionState === 'success'"
				class="rpb_newsletter__thankyou rpb_newsletter--active"
			>
				<h3>Sie haben Post!</h3>
				<h4>Um die Anmeldung erfolgreich abzuschließen, klicken Sie bitte auf den Link in der E-Mail, die Sie gerade erhalten haben.</h4>
			</div>
			<div
				v-if="subscriptionState === 'error'"
				class="rpb_newsletter__error rpb_newsletter--active"
			>
				<h3>Hoppla ...</h3>
				<h4 v-if="typeOfError === 'duplicate'">
					Scheinbar sind Sie bereits für den Newsletter angemeldet. Falls nicht, schreiben Sie uns gerne ein Mail an <a :href="`mailto:${marketingEmail}`">{{ marketingEmail }}</a>, wir kümmern uns darum! <a
						href="#"
						@click.prevent="resetState"
					>Mit anderer Mailadresse versuchen.</a>
				</h4>
				<h4 v-if="typeOfError === 'invalid'">
					Das ist keine gültige E-Mail Adresse. <a
						href="#"
						@click.prevent="resetState"
					>Bitte versuchen Sie es noch einmal.</a>
				</h4>
				<h4 v-if="typeOfError === 'unknown'">
					Ein unbekannter Fehler ist aufgetreten. <a
						href="#"
						@click.prevent="resetState"
					>Bitte versuchen Sie es später noch einmal.</a>
				</h4>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { getBasePath, getBaseUrl } from '@utils/environmentUtils';
import { computed, onMounted, ref } from 'vue';
import { ClientType } from '@/interfaces/common';

interface Props {
	loading: boolean,
	typeOfError: string,
	subscriptionState: string,
	client?: ClientType,
}

const props = withDefaults(defineProps<Props>(), {
	client: 'at'
});

const emit = defineEmits(['newsletter:resetState', 'newsletter:openPrivacy', 'newsletter:subscribe']);

const checkboxModel = ref(false);

const invalidEmail = ref(false);

const invalidCheckbox = ref(false);

const show = ref(true);

const baseUrl = getBaseUrl();

const basePath = getBasePath();

const email = ref('');

const background = ref('background-image: url("' + baseUrl + '/fileadmin/2/restplatzboerse/all/img/footer/back.jpg");');

const marketingEmail = computed(() : string => {
	if (props.client === 'kuat') {
		return 'barbara.winter@kuoni.at';
	}
	if (props.client === 'ch') {
		return 'marketing@restplatzboerse.ch';
	}
	if (props.client === 'com') {
		return 'marketing@restplatzboerse.com';
	}
	return 'marketing@restplatzboerse.at';
});

const resetState = (): void => {
	email.value = '';
	checkboxModel.value = false;
	emit('newsletter:resetState', email.value);
};

const openPrivacy = (): void => {
	emit('newsletter:openPrivacy', email.value);
};
const closeNewsletter = (): void => {
	show.value = false;
};

const onEscapeKeyUp = (event: KeyboardEvent): void => {
	if (event.key === 'Escape') {
		window.removeEventListener('keyup', onEscapeKeyUp);
		closeNewsletter();
	}
};

const checkForm = (): void => {
	invalidEmail.value = !email.value;
	invalidCheckbox.value = !checkboxModel.value;

	if (email.value && checkboxModel.value) {
		emit('newsletter:subscribe', email.value);
	}
};

onMounted(() => {
	window.addEventListener('keyup', onEscapeKeyUp);
});
</script>

<style lang="scss" scoped>
.rpb_newsletter {
	max-width: 144rem;
	height: auto;
	position: fixed;
	bottom: 0;
	right: 1.1rem;
	left: 0.5rem;
	box-shadow: 0.6rem 0.6rem 3.5rem rgb(0 0 0 / 30%);
	margin: 2rem;
	padding: 1rem;
	background-color: $color-page-background;
	background-position: center center;
	background: linear-gradient(to right, $color-primary-l3, $color-primary-l6);
	display: none;
	flex-direction: row-reverse;
	z-index: 2040;

	&__form {
		display: block;
		margin: 1rem 0;

		input {
			flex-grow: 1;
			padding: 0.5em 0.75em;
			border-radius: $border-radius-small;
			margin-bottom: 0.8rem;
		}

		button {
			padding: 0.8rem 1.5rem;
			background: $color-extra;
			color: $color-page-background;
			border: 0;
			bottom: 0.8rem;
			right: 0.8rem;
			text-align: center;
			border-radius: 1.5rem;
			font-size: $font-small-2;
			font-weight: $font-weight-regular;
			letter-spacing: 0.1rem;
			width: 100%;
			cursor: pointer;
		}

		.button-disabled {
			background: $color-search-mask-border;
		}
	}

	&__formfield {
		background: $color-page-background;
		width: 100%;
		height: 100%;
		min-height: 17rem;
		padding: 0.7rem 2rem;
		border-radius: $border-radius-small;
		align-items: center;
		flex: 0 1 61rem;
		position: relative;
		overflow: hidden;

		.rpb_envelope {
			position: relative;
			width: 2.5rem;
			height: 2rem;
			bottom: 0;
			margin-bottom: -0.2rem;
			vertical-align: baseline;
		}

		h3 {
			display: inline-block;
			font-family: $font-family-special;
			padding: 0;
			font-size: $font-large-4;
			font-weight: $font-weight-semibold;
			color: $newsletter-font-color;
			line-height: 1.5;
		}

		h4 {
			font-weight: $font-weight-semibold;
			color: #333;
			font-size: $font-small-3;
			padding: 0;
		}

		svg {
			fill: $color-link;
		}

		label {
			font-weight: $font-weight-semibold;
		}

		.formInput::placeholder {
			color: #ccc;
		}
	}

	.formElement,
	.formInput {
		height: 4rem;
		width: 100%;
		padding: 0.1rem 0 0.1rem 0.5rem;
		border: 0.1rem solid #d8d8d8;
		background: #fff;
		font-size: $font-small-1;
		outline: 0;

		&:focus {
			border: 0.2rem solid $input-focus;
			box-shadow: 0.3rem 0.3rem 1.5rem rgb(0 0 0 / 17%);
		}
	}

	.button {
		display: inline-block;
		border-radius: $border-radius-x-large;
		width: auto;
		min-width: 20rem;
		position: absolute;
		height: 3.2rem;
		font-size: $font-small-2;
		font-weight: $font-weight-regular;
		background: $color-extra;
		color: #FFF;
		border: 0;
		bottom: 0.8rem;
		right: 0.8rem;
		text-align: center;
		padding: 0.7rem 0;
		cursor: pointer;
	}

	&__subscribe,
	&__subscribe__headline,
	&__sending,
	&__thankyou {
		display: none;
	}

	&__sending {
		width: 100%;
		height: 100%;
		background: rgb(255 255 255 / 90%);
		position: absolute;
		inset: 0;

		.icon {
			color: #eee;
			transform: rotate(-7deg);
			width: 12rem;
		}

		.rpb_envelope {
			position: relative;
			top: 1.5rem;
			display: inline-block;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			animation: envelope-fly 1.8s infinite ease-in-out;
		}

		@keyframes envelope-fly {
			0% { opacity: 0; left: 0; transform: rotate(-30deg); }
			20% { opacity: 1; left: 3rem; transform: rotate(7deg); }
			40% { opacity: 0.8; left: 6rem; transform: rotate(-7deg); }
			60% { opacity: 1; left: 9rem; transform: rotate(7deg); }
			80% { opacity: 0.8; left: 12rem; transform: rotate(-7deg); }
			100% { opacity: 0; left: 15rem; transform: rotate(30deg); }
		}
	}

	&--active {
		display: inline-block;
		animation: newsletter-fadein 0.7s ease-in;
		opacity: 1;
	}

	@media screen and (width >= 455px) {
		&__form {
			display: flex;

			input {
				border-radius: $border-radius-small;
				padding: 0.5em 0.75em;
				margin-bottom: 0;
			}

			button {
				width: auto;
				border-radius: $border-radius-xx-large;
				padding: 0.5rem 1.5rem;
				margin-left: 0.5rem;
			}
		}

		&__formfield {
			.rpb_envelope {
				width: 4rem;
				height: 3rem;
			}

			h3 {
				font-size: 3.5rem;
			}

			h4 {
				font-size: $font-small-1;
			}
		}
	}

	@media screen and (min-width: $breakpoint-verysmall) {
		&__formfield {
			.rpb_envelope {
				width: 4.7rem;
				height: 4rem;
				margin-bottom: -0.4rem;
			}

			h3 {
				font-size: 4.8rem;
			}
		}
	}

	@media screen and (min-width: $breakpoint-small) {
		background-size: cover;
	}

	@media screen and (min-width: $breakpoint-verylarge) {
		margin: 2rem auto;
	}
} // ende .rpb_newsletter

.rpb_close-icon {
	content: "";
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	border: 0.5rem solid $color-border;
	position: absolute;
	right: -2.3rem;
	top: -2.3rem;
	z-index: 2;
	background-color: $color-page-background;
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='16' height='19' viewBox='0 0 27 32'%3E%3Cpath d='M15.25 16.188l11 10.938c0.625 0.625 0.625 1.563 0 2.188-0.563 0.625-1.563 0.625-2.188 0l-10.688-10.75-10.75 10.75c-0.625 0.625-1.625 0.625-2.188 0-0.625-0.625-0.625-1.563 0-2.188l11-10.938v-0.375l-11-10.938c-0.625-0.625-0.625-1.563 0-2.188 0.563-0.625 1.563-0.625 2.188 0l10.75 10.75 10.688-10.75c0.625-0.625 1.625-0.625 2.188 0 0.625 0.625 0.625 1.563 0 2.188l-11 10.938v0.375z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	border-color: $input-focus;
}

.rpb_newsletter--fadein {
	display: flex;
	animation: newsletter-fadein 0.7s ease-in;
	opacity: 1;
}

@keyframes newsletter-fadein {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

.rpb_newsletter--fadeout {
	display: inline-block;
	animation: newsletter-fadeout 0.2s ease-in;
	opacity: 0;
}

@keyframes newsletter-fadeout {
	0% { opacity: 1; }
	100% { opacity: 0; }
}

.checkbox--checked {
	box-shadow: 1.5rem 0 0 $color-white, -1.5rem 0 0 $color-white;
	background-color: $color-white;
}

.checkbox {
	position: relative;
	font-size: $font-small-1;
	font-weight: 200;

	.checkbox__label {
		display: inline-block;
		position: relative;
		padding-left: 2.3em;
		border: none;
		background: none;
		color: $color-black;
		vertical-align: middle;
		cursor: pointer;

		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 0;
			width: 1.5em;
			height: 1.5em;
			transform: translateY(-50%);
			border: 0.2rem solid $color-primary-l4;
			border-radius: 0.5rem;
			background: $color-white;
		}
	}

	.checkbox__icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0.5rem;
		width: 1.5rem;
		height: 1.5rem;
		display: none;
		z-index: 1;
		cursor: pointer;
		fill: $filter-checkmark-color;
	}

	.checkbox__input {
		position: absolute;
		z-index: -1;
		opacity: 0;

		&:focus + .checkbox__label::before {
			box-shadow: 0 0 0 0.1rem $color-primary-l1;
		}
	}
}

.invalidCheckbox {
	.checkbox__label {
		color: $color-warning;

		&::before {
			border-color: $color-warning;
		}
	}
}

.invalidEmail {
	.formInput::placeholder {
		color: $color-warning;
	}

	.formInput {
		border-color: $color-warning;
	}
}

.checkbox--checked .checkbox__icon {
	display: block;
}

</style>
