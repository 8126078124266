import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "favorited-hotel" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "favorited-hotel__info" }
const _hoisted_5 = { class: "favorited-hotel__heading-wrapper" }
const _hoisted_6 = { class: "favorited-hotel__heading" }
const _hoisted_7 = { class: "favorited-hotel__region" }

import HotelStars from '@lmt-rpb/HotelStars/HotelStars.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { getFluxImageUrl } from '@/js/utils/utils';
import { computed } from 'vue';

interface Props {
	title: string,
	iffCode: number,
	url: string,
	region: string,
	hotelRating: number,
	showSuns?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FavoritedHotelItem',
  props: {
    title: {},
    iffCode: {},
    url: {},
    region: {},
    hotelRating: {},
    showSuns: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const imageUrl = computed(() => {
	const url = getFluxImageUrl();
	return !url ? 'https://images.flux.reisen' : url;
});

const smallImage = computed(() => `${imageUrl.value}/hotel/${props.iffCode}/1/s`);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("a", {
      class: "favorited-hotel__link",
      href: _ctx.url,
      rel: "nofollow"
    }, [
      _createElementVNode("img", {
        class: "favorited-hotel__img",
        src: smallImage.value,
        alt: _ctx.title
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.title), 1 /* TEXT */),
          _createElementVNode("button", {
            class: "favorited-hotel__close-button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('FavoritedHotelItem:Delete')), ["prevent","stop"]))
          }, [
            _createVNode(BaseIcon, {
              name: "closeCircle",
              class: "favorited-hotel__icon"
            })
          ])
        ]),
        _createVNode(HotelStars, {
          class: "favorited-hotel__rating",
          rating: _ctx.hotelRating,
          "hotel-box": false,
          "show-suns": _ctx.showSuns
        }, null, 8 /* PROPS */, ["rating", "show-suns"]),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.region), 1 /* TEXT */)
      ])
    ], 8 /* PROPS */, _hoisted_2)
  ]))
}
}

})