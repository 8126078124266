import { paramsToForm } from '@components/common/services/transform';
import { useStore } from '@components/common/store';
import { EventBus } from '@global-js/event-bus';
import { dashedDate } from '@/js/utils/dateUtils';
import { SearchFormDataType, SuggestionDataType } from '@/interfaces/search-form';

const store = useStore();

// TODO: create interface for all allowed params in another file. To see all allowed params check: /app/src/restplatzboerse/all/components/common/services/url.ts
export interface SearchMaskQueryData {
	destination?: string,
	dur: string,
	ddate: string,
	rdate: string,
	ibe: string,
	depap: string,
	child: string,
	adult: string
}

export function extractTypoDataInfo(searchMaskDataSelector: string) {
	try {
		const searchMaskDataElement = (document.querySelector(searchMaskDataSelector) as HTMLElement);
		const searchMaskData = searchMaskDataElement.dataset as unknown as SearchMaskQueryData;
		const searchMaskUrlParams = prepareQueryParams(searchMaskData);

		const formData = paramsToForm(searchMaskUrlParams);
		return prepareUpdateFormData(formData, searchMaskData.destination);
	} catch (e) {
		console.error(e);
		return {};
	}
}

export async function initStore(searchMaskDataSelector: string) {
	const updateFormData = extractTypoDataInfo(searchMaskDataSelector);
	await store.commit('searchMask/updateFormData', updateFormData);
	EventBus.$emit('Store:Initialized');
}

function prepareQueryParams(searchMaskData: SearchMaskQueryData) {
	const {
		dur, ddate, rdate, ibe, depap, child, adult
	} = searchMaskData;

	const ddateFormatted = convertToDateIfNumber(ddate);
	const rdateFormatted = convertToDateIfNumber(rdate);
	return {
		dur, ddate: ddateFormatted, rdate: rdateFormatted, ibe, depap, adult, child
	};
}

function prepareUpdateFormData(formData: Partial<SearchFormDataType>, destination: string | undefined): Partial<SearchFormDataType> {
	const {
		offerDuration, travelDuration, onlyHotel, departure, travelers
	} = formData;
	const travelersData = {
		adult: travelers?.adult,
		children: travelers?.children ?? [],
	};

	const updateFormData: Partial<SearchFormDataType> = {
		offerDuration,
		travelDuration,
		onlyHotel,
		...(travelersData?.adult ? { travelers: travelersData } : {}),
	};
	if (departure) {
		updateFormData.departure = departure;
	}
	if (destination) {
		updateFormData.destination = JSON.parse(destination) as SuggestionDataType;
	}
	return updateFormData;
}

function convertToDateIfNumber(date: string) {
	if (Number(date)) {
		return dashedDate(new Date(Number(date)));
	}
	return date;
}
