import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "accordion__desktop" }
const _hoisted_2 = { class: "rpb_headline__h3" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "accordion__collapse-header" }
const _hoisted_5 = { class: "accordion__collapse-body" }
const _hoisted_6 = ["innerHTML"]

import Collapse from '@lmt-rpb/Collapse/Collapse.vue';

interface Props {
	title: string,
	bodyText: string,
	mobileAccordionOnly?: boolean,
	isOpen?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Accordion',
  props: {
    title: {},
    bodyText: {},
    mobileAccordionOnly: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["accordion", { 'accordion--mobileAccordionOnly': _ctx.mobileAccordionOnly }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
      _createElementVNode("p", {
        class: "accordion__desktop--body",
        innerHTML: _ctx.bodyText
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createVNode(Collapse, {
      class: "accordion__collapse",
      open: _ctx.isOpen
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1 /* TEXT */)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", {
            class: "accordion__collapse-text",
            innerHTML: _ctx.bodyText
          }, null, 8 /* PROPS */, _hoisted_6)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["open"])
  ], 2 /* CLASS */))
}
}

})