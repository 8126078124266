<template>
	<Trustpilot
		v-if="showTrustpilot"
		v-cloak
		v-observe-visibility="{
			callback: visibilityChanged,
			once: true,
			intersection: {
				root: null,
				rootMargin: '100px 0px 0px 0px'
			}
		}"
		:flux-rest-api-url="fluxRestApiUrl"
		:base-u-r-l="baseURL"
		:trustpilot-url="trustpilotUrl"
		:client="client"
		:reviews="reviews"
		:total="total"
	/>
</template>

<script lang="ts" setup>
import Trustpilot, { Total } from '@lmt-rpb/Trustpilot/Trustpilot.vue';
import { getFluxApiUrl } from '@utils/utils';
import { determineClient, getClientString } from '@utils/environmentUtils';

import axios, { AxiosResponse } from 'axios';
import { ref } from 'vue';
import { TrustpilotReview } from '@/interfaces/common';
import { ObserveVisibility as vObserveVisibility } from 'vue-observe-visibility';

interface Props {
	baseURL: string,
	trustpilotUrl: string,
	minStars: string,
	limit: string,
}

const props = withDefaults(defineProps<Props>(), {
});

const reviews = ref<TrustpilotReview[]>([]);

const total = ref<Total>({ reviews: 0, stars: 0 });

const showTrustpilot = ref(true);

const client = determineClient(props.baseURL);

const fluxRestApiUrl = ref(getFluxApiUrl());

const clientStringValue = getClientString();

const getReviews = (): void => {
	// eslint-disable-next-line no-undef
	axios({
		method: 'GET',
		url: fluxRestApiUrl.value + '/v1/trustpilot',
		headers: {
			'content-type': 'application/json'
		},
		params: {
			client: clientStringValue,
			'min-stars': props.minStars,
			limit: props.limit
		}
	})
		.then((response: AxiosResponse) => {
			if (response.data && Object.keys(response.data).length) {
				reviews.value = response.data.reviews;
				total.value.reviews = response.data.reviews_total;
				total.value.stars = response.data.stars;
				if (total.value.stars <= 2) {
					showTrustpilot.value = false;
				}
			} else {
				// eslint-disable-next-line no-console
				console.error('No trustpilot reviews were loaded.');
			}
		})
		.catch(function(error) {
			// eslint-disable-next-line no-console
			console.log(error);
		});
};

const visibilityChanged = (isVisible: boolean) => {
	if (isVisible) getReviews();
};
</script>
