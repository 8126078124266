import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title", "href"]
const _hoisted_2 = {
  class: "search-suggest__item-header search-suggest__item",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "search-suggest__text-container" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "search-suggest__title-word--additional" }
const _hoisted_6 = { key: 0 }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	url: string,
	item: {
		type: string;
		title: string;
		url: string;
		subtitle: string;
	}
}

export default /*@__PURE__*/_defineComponent({
  __name: 'SearchSuggestItem',
  props: {
    url: {},
    item: {}
  },
  emits: ['SearchSuggestItem:Loading'],
  setup(__props: any, { emit: __emit }) {

const getImagePath = (type: string): string => ({
	countries: 'location',
	regions: 'location',
	cities: 'location',
	hotels: 'home',
	shops: 'store',
	pages: 'pages',
	topicpages: 'pages',
	regiongroups: 'location',
	countryhotels: 'location',
	regionhotels: 'location',
	cityhotels: 'location',
} as {[key :string]: string})[type];

const emit = __emit;



const handleClick = (event: MouseEvent | KeyboardEvent) => {
	if (!event.ctrlKey && !event.altKey && !event.shiftKey && !event.metaKey) {
		emit('SearchSuggestItem:Loading');
	}
};

const removeBoldTags = (item: string, replaceValue: string): string => item.replace(/<b>/g, replaceValue).replace(/<\/b>/g, replaceValue);


return (_ctx: any,_cache: any) => {
  return (_ctx.item.title)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        title: removeBoldTags(_ctx.item?.title, ''),
        class: "search-suggest__item-link",
        href: _ctx.url,
        onClick: handleClick
      }, [
        _createElementVNode("li", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(BaseIcon, {
              name: getImagePath(_ctx.item?.type),
              class: "search-suggest__icons"
            }, null, 8 /* PROPS */, ["name"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: "search-suggest__title-word",
              innerHTML: _ctx.item.title
            }, null, 8 /* PROPS */, _hoisted_4),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.item.subtitle)
                ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(removeBoldTags(_ctx.item?.subtitle, ' ')), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ])
          ])
        ])
      ], 8 /* PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}
}

})