import { HotelGridService } from './hotelGridService';

function createHotelGridObserver(selector: string, service: HotelGridService): void {
	const observer = new IntersectionObserver(
		(entries) => {
			if (entries[0].isIntersecting) {
				service.initHotelGrid(`.${entries[0].target.classList[1]}`);
			}
		},
		{ root: null, rootMargin: '200px' }
	);

	const target = document.querySelector(selector);
	if (target) observer.observe(target);
}

function createUniqueSelectorList(gridMountPoints: NodeListOf<Element>): string[] {
	const vueSelectorList: string[] = [];

	gridMountPoints.forEach((mountPoint, index) => {
		const selector = `rpb_hotelgrid__mountpoint--${index}`;
		mountPoint.classList.add(selector);
		vueSelectorList.push(`.${selector}`);
	});

	return vueSelectorList;
}

function initializeHotelGrids(): void {
	const gridMountPoints = document.querySelectorAll('.rpb_hotelgrid__mountpoint');
	if (!gridMountPoints.length) return;

	const vueUniqueSelectorList = createUniqueSelectorList(gridMountPoints);

	vueUniqueSelectorList.forEach((selector) => {
		const hotelGridService = new HotelGridService();
		const element = document.querySelector(selector) as HTMLElement;
		const fromPlugin = !!element?.parentNode?.querySelector(
			'.rpb_hotelgrid--box-data[data-from-plugin="true"]'
		);

		if (!fromPlugin) {
			hotelGridService.initHotelGrid(selector);
		} else {
			createHotelGridObserver(selector, hotelGridService);
		}
	});
}

initializeHotelGrids();
