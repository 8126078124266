import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-field" }

import SearchSuggest from '@lmt-rpb/SearchSuggest/SearchSuggest.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import {
	computed, nextTick, ref, onBeforeMount, onBeforeUnmount,
} from 'vue';

import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Search',
  setup(__props, { expose: __expose }) {


const store = useStore();

const modalSearchSuggest = ref<InstanceType<typeof SearchSuggest> | null>(null);

const showModal = ref(false);

const isMinTablet = computed((): boolean => store.state.config.isMinTablet);

const toggleModal = (): void => {
	showModal.value = !showModal.value;

	nextTick(() => {
		modalSearchSuggest.value?.input?.focus();
	});
};

function close() {
	modalSearchSuggest.value?.clearSuggestAndClose();
	showModal.value = false;
}

onBeforeMount(() => {
	window.addEventListener('pageshow', close);
});

onBeforeUnmount(() => {
	window.removeEventListener('pageshow', close);
});

__expose({
	SearchSuggest,
	FormField,
	Modal,
	showModal
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isMinTablet.value)
      ? (_openBlock(), _createBlock(SearchSuggest, {
          key: 0,
          inline: false,
          icon: "search"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(FormField, {
            class: "search-field__field",
            "show-modal": showModal.value,
            "show-toggle-icon": false,
            placeholder: 'Website durchsuchen',
            icon: "search",
            onClick: toggleModal,
            "onFormField:Clear": close
          }, null, 8 /* PROPS */, ["show-modal"]),
          _createVNode(Modal, {
            modelValue: showModal.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
            class: "search-field__modal",
            "initial-render": true,
            title: 'Website durchsuchen',
            "offer-duration-modal": true,
            "with-footer": false,
            "onModal:Close": close
          }, {
            default: _withCtx(() => [
              _createVNode(SearchSuggest, {
                ref_key: "modalSearchSuggest",
                ref: modalSearchSuggest,
                inline: true,
                icon: "search",
                "custom-clear-icon": 'clearSearch'
              }, null, 512 /* NEED_PATCH */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}
}

})