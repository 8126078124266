<template>
	<section
		ref="teaserElement"
		class="teaser"
	>
		<ComponentHeader
			:header-props="headerProps"
		/>
		<div
			ref="swiperRef"
			class="teaser__scroll-container"
		>
			<swiper-container
				v-bind="swiperSettings"
				:modules="[Navigation]"
				:navigation="{
					nextEl: `#${uniqueId}-next`,
					prevEl: `#${uniqueId}-prev`
				}"
				:class="swiperScrollClasses"
				@swiperslidechange="handleSlideChange"
			>
				<swiper-slide
					v-for="(item, index) in items"
					:key="index"
					:title="item.text"
					class="swiper-slide"
				>
					<a
						:href="isDragging ? undefined : item.link"
						class="teaser__item"
						data-ga-event="select_content"
						data-ga-content_type="Teaser Item"
						:data-ga-item_id="item.text"
					>
						<img
							class="teaser__item-image rpb_lazy"
							data-sizes="auto"
							:data-src="item.image"
							src="data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw=="
							:alt="item.text"
							@mousedown="onMouseDown"
							@mouseup="onMouseUp"
							@mousemove="onMouseMove"
						/>
						<div class="teaser__item-footer">
							<span class="teaser__item-name">{{ item.text }}</span>
							<span
								v-if="item.price"
								class="teaser__item-price"
							>
								<small>ab </small>
								<span class="teaser__item-price-formatted">{{ formatPrice(item.price, currency) }}</span>
							</span>
						</div>
					</a>
				</swiper-slide>
			</swiper-container>
			<div
				:id="`${uniqueId}-prev`"
				class="swiper-button-prev"
			>
				<span class="teaser__arai-label">prev</span>
				<BaseIcon
					name="chevron"
					class="teaser__prev-icon"
				/>
			</div>
			<div
				:id="`${uniqueId}-next`"
				class="swiper-button-next"
			>
				<span class="teaser__arai-label">next</span>
				<BaseIcon
					name="chevron"
					class="teaser__next-icon"
				/>
			</div>
		</div>
	</section>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import type { Swiper as SwiperType } from 'swiper';
import { register } from 'swiper/element/bundle';
import { Navigation } from 'swiper/modules';
import { addTopMarginToFirstElement } from '@utils/utils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import { formatPrice } from '@/js/utils/priceUtils';
import { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';

register();

export interface TeaserData {
	text: string;
	link: string;
	price: number;
	image: string;
}

interface Props {
	headerProps: HeaderProps,
	items: TeaserData[]
	currency?: string;
}

withDefaults(defineProps<Props>(), {
	currency: 'EUR'
});

const teaserElement = ref<HTMLElement | null>(null);
const uniqueId = CSS.escape('id-' + uuidv4());
const swiperRef = ref<SwiperType | null>(null);

const showLeftOverlay = ref(false);
const showRightOverlay = ref(true);

const isMouseDown = ref(false);
const isDragging = ref(false);

const swiperScrollClasses = computed(() => [
	'teaser__scroll',
	{ 'teaser__scroll--left': showLeftOverlay.value },
	{ 'teaser__scroll--right': showRightOverlay.value },
]);

const swiperSettings = {
	slidesPerView: 1.25,
	spaceBetween: 15,
	touchRatio: 1,
	breakpoints: {
		544: { // breakpoint-verysmall
			slidesPerView: 2.25,
			touchRatio: 1,
		},
		768: { // breakpoint-small
			slidesPerView: 3,
			touchRatio: 1,
		},
		1200: { // breakpoint-large
			slidesPerView: 4,
			touchRatio: 0,
		}
	}
};

const addMarginTop = (): void => {
	if (teaserElement.value) {
		addTopMarginToFirstElement(teaserElement.value);
	}
};

const onMouseDown = (): void => {
	isMouseDown.value = true;
};

const onMouseUp = (e: MouseEvent): void => {
	setTimeout(() => {
		e.preventDefault();
		e.stopPropagation();

		isMouseDown.value = false;
		isDragging.value = false;
	}, 300);
};

const onMouseMove = (): void => {
	if (!isMouseDown.value || isDragging.value) {
		return;
	}

	isDragging.value = true;
};

const handleSlideChange = (swiperEvent: CustomEvent | undefined) => {
	if (!swiperEvent?.detail[0]) return;

	showLeftOverlay.value = !swiperEvent.detail[0].isBeginning;
	showRightOverlay.value = !swiperEvent.detail[0].isEnd;
};

onMounted(() => {
	addMarginTop();
});

</script>

<style lang="scss" scoped>
.teaser {
	max-width: 144rem;
	margin: 0 auto $vgrid-mobile-large;

	&--margin-top {
		margin-top: $vgrid-mobile-first-element-top-margin;
	}

	.teaser__scroll-container {
		position: relative;
	}

	.teaser__scroll {
		position: relative;
		margin-top: 1.2rem;
		padding: 0.5rem;

		&--left::before,
		&--right::after {
			content: '';
			position: absolute;
			z-index: 2;
			top: 0;
			bottom: 0;
			width: 2.3rem;
		}

		&--left::before {
			left: 0;
			background: linear-gradient(90deg, $color-white 0%, $color-white-t0 100%);
		}

		&--right::after {
			right: 0;
			background: linear-gradient(90deg, $color-white-t0 0%, $color-white 100%);
		}
	}

	.swiper-slide {
		padding-bottom: 0.2rem;
	}

	.teaser__item {
		display: flex;
		flex-direction: column;
		border-radius: 1.5rem;
		box-shadow: -0.2rem 0.2rem 0 0 $color-lime-light;

		&:hover {
			text-decoration: none;
		}
	}

	.teaser__item-image {
		width: 100%;
		height: 100%;
		border-top-left-radius: 1.5rem;
		border-top-right-radius: 1.5rem;
		object-fit: cover;
	}

	.teaser__item-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		color: $color-primary;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
	}

	.teaser__item-name {
		font-size: $font-medium-3;
	}

	.teaser__item-price {
		@include media-query-up($breakpoint-small) {
			:deep(small) {
				font-size: $font-small-1;
			}
		}

		& {
			color: $color-extra;
			font-size: $font-medium-3;
			text-align: right;
			white-space: nowrap;
		}

		:deep(small) {
			font-size: $font-small-3;
		}
	}

	.teaser__arai-label {
		@include sr-only;
	}

	.swiper-button-prev,
	.swiper-button-next {
		@include visible-from($breakpoint-small, $display: flex);

		width: 5rem;
		height: 5rem;
		background: $color-white;
		border-radius: 100%;
		border: 0.1rem solid $color-primary-l4;
		align-items: center;
        justify-content: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 5;
		cursor: pointer;

		&::after {
			display: none;
		}

		&.swiper-button-disabled {
			display: none;
		}
	}

	.swiper-button-prev {
		left: 1.5rem;

		@include media-query-up($breakpoint-verylarge) {
			left: -2rem;
		}
	}

	.swiper-button-next {
		right: 1.5rem;

		@include media-query-up($breakpoint-verylarge) {
			right: -2rem;
		}
	}

	.teaser__prev-icon,
	.teaser__next-icon {
		width: 2rem;
		height: 2rem;
		fill: $color-primary;

		&.is-thumb {
			width: 2rem;
			height: 2rem;
		}
	}

	.teaser__prev-icon {
		transform: rotate(-90deg);
	}

	.teaser__next-icon {
		transform: rotate(90deg);
	}

	@include media-query-up($breakpoint-small) {
		margin-bottom: $vgrid-desktop-large;

		&--margin-top {
			margin-top: $vgrid-desktop-first-element-top-margin;
		}
	}
}
</style>
