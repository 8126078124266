import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "header-iconbar__favorite-icon" }
const _hoisted_2 = {
  x: "13",
  y: "17",
  "text-anchor": "middle",
  "dominant-baseline": "top"
}

import { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';
import { getCookieHotels, updateCookie, triggerFavoriteChange } from '@/js/modules/bookmark';
import { EventBus } from '@global-js/event-bus';
import {
	onBeforeMount,
	onBeforeUnmount,
	Ref,
	ref,
} from 'vue';
import BookmarkAnalytics from '@/js/modules/analytics/BookmarkAnalytics';
import * as hotelListService from '@services/hotelListService';
import HeaderFavoriteDropdown from './HeaderFavoriteDropdown.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderFavoriteButton',
  setup(__props) {

const headerFavoriteDropdown = ref<InstanceType<typeof HeaderFavoriteDropdown> | null>(null);

const root = ref<InstanceType<typeof HTMLDivElement> | null>(null);

const showDropdown = ref(false);

const hotels:Ref<FavoritedHotel[]> = ref([]);

const updateCookieHotels = (apiHotels: FavoritedHotel[]) => {
	const analytics = BookmarkAnalytics.fromFavoritedHotel(apiHotels);
	analytics.fireViewCart();
	if (apiHotels.length !== hotels.value.length) {
		updateCookie(apiHotels);
		triggerFavoriteChange(); // signalises that cookies changed, which fires updateHotel()
	}
};

const requestAndUpdateHotels = async() => {
	if (hotels.value.length) {
		try {
			const hotelIds = hotels.value.map((h: FavoritedHotel) => Number(h.IffCode));
			updateCookieHotels(await hotelListService.get(hotelIds));
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}
		}
	}
};

const closeFavorite = (event: MouseEvent) => {
	const target = event.target as HTMLElement;
	if (!headerFavoriteDropdown.value?.$el.contains(target)) {
		document.body.classList.remove('header-favorite-open');
		document.body.removeEventListener('click', closeFavorite, true);
		showDropdown.value = false;
		if (root.value?.contains(target)) {
			event.stopPropagation();
		}
	}
};

const openFavorite = () => {
	document.body.classList.add('header-favorite-open');
	if (document.body.classList.contains('search-open')) {
		document.body.classList.remove('page-header--hidden'); // Fixes vanishing of headerbar
	}
	showDropdown.value = true;
	requestAndUpdateHotels();
	document.body.addEventListener('click', closeFavorite, true);
};

const updateHotel = () => {
	getCookieHotels().then((hotelsCookie) => { hotels.value = hotelsCookie; });
};

onBeforeMount(() => {
	updateHotel();
	EventBus.$on('bookmark:favoriteChange', updateHotel);
});

onBeforeUnmount(() => {
	EventBus.$off('bookmark:favoriteChange', updateHotel);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "root",
    ref: root,
    class: "header-iconbar__entry-wrapper"
  }, [
    _createElementVNode("button", {
      class: "header-iconbar__entry header-iconbar__entry-favorite",
      onClick: _withModifiers(openFavorite, ["prevent"])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "header-iconbar__favorite-icon--close" }, null, -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("svg", {
          class: "header-iconbar__favorite-icon-heart",
          width: "32",
          height: "27",
          viewBox: "0 0 27 32"
        }, [
          _createElementVNode("path", {
            fill: "#333",
            d: "M13.813 29.063l-0.375-0.313-0.438-0.375-0.625-0.438-0.563-0.5-0.5-0.438-0.875-0.75-0.563-0.438-0.688-0.625-0.625-0.563-2.125-2.125-0.625-0.688-1.25-1.25-0.688-0.813-0.563-0.75-0.688-0.75-0.438-0.688-0.563-0.813-0.563-0.875-0.438-0.938-0.313-0.938-0.188-0.938-0.125-0.875v-1.5l0.063-0.75 0.25-1.125 0.25-0.75 0.25-0.563 0.25-0.625 0.438-0.75 0.688-0.875 0.688-0.688 1.125-0.813 0.875-0.438 1-0.313 0.625-0.125h0.438l1.438 0.063 1.375 0.375 0.875 0.5 0.75 0.5 0.625 0.563 0.75 0.75 0.5 0.688 0.25 0.563h0.125l0.125-0.25 0.125-0.313 0.25-0.313 0.375-0.563 0.375-0.438 0.438-0.438 0.563-0.5 0.75-0.5 0.75-0.313 0.875-0.375 1.188-0.188h0.688l1.125 0.125 1.125 0.375 0.813 0.438 1.188 0.813 0.75 0.875 0.813 1.125 0.563 1.125 0.5 1.438 0.25 1.5-0.125 2.063-0.063 0.75-0.313 1-0.25 0.688-0.938 1.688-0.688 1.063-0.813 1.063-0.75 0.875-0.938 1.188-0.813 0.938-0.875 0.875-1.313 1.375-0.75 0.75-1 0.938-1.438 1.375-1.5 1.25z"
          })
        ], -1 /* HOISTED */)),
        (_openBlock(), _createElementBlock("svg", {
          class: _normalizeClass(["header-iconbar__favorite-icon-count", {'header-iconbar__favorite-icon-count--visible': hotels.value.length}]),
          width: "24",
          height: "18",
          viewBox: "0 0 30 30"
        }, [
          _cache[0] || (_cache[0] = _createElementVNode("circle", {
            cx: "12",
            cy: "12",
            r: "11"
          }, null, -1 /* HOISTED */)),
          _createElementVNode("text", _hoisted_2, _toDisplayString(hotels.value.length), 1 /* TEXT */)
        ], 2 /* CLASS */))
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "header-iconbar__entry-label" }, " Merkzettel ", -1 /* HOISTED */))
    ]),
    _createVNode(HeaderFavoriteDropdown, {
      ref_key: "headerFavoriteDropdown",
      ref: headerFavoriteDropdown,
      "show-dropdown": showDropdown.value,
      hotels: hotels.value
    }, null, 8 /* PROPS */, ["show-dropdown", "hotels"])
  ], 512 /* NEED_PATCH */))
}
}

})