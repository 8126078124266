import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "search-suggest__load-box"
}
const _hoisted_2 = {
  key: 0,
  class: "search-suggest__load-box-suggest"
}

import { debounce } from '@utils/utils';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import {
	computed, onBeforeUnmount, onMounted, ref, nextTick, onBeforeMount, watch,
} from 'vue';
import { useStore } from '@/components/common/store';
import SerachSuggestItem from '@lmt-rpb/SearchSuggestItem/SearchSuggestItem.vue';
import * as searchSuggestService from '@services/searchSuggestService';
import { SuggestionElements } from '@/interfaces/api/v1-search-suggest';

interface Props {
	inline?: boolean,
	icon?: string,
	customClearIcon?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchSuggest',
  props: {
    inline: { type: Boolean, default: false },
    icon: { default: '' },
    customClearIcon: { default: undefined }
  },
  setup(__props: any, { expose: __expose }) {


const store = useStore();

const props = __props;

const searchSuggestItemList = ref<InstanceType<typeof SerachSuggestItem>[]>([]);

const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const formField = ref<InstanceType<typeof FormField> | null>(null);

const term = ref<string>('');

const isMinTablet = computed((): boolean => store.state.config.isMinTablet);

const isMobile = computed((): boolean => store.state.config.isMobile);

const toggleDropdown = ref<boolean | undefined>(props.inline);
const emptyState = [{
	type: '', title: '', url: '', subtitle: ''
}];

const headerHamburgerBtn = ref<HTMLElement | null>(null);

const searchSuggests = ref<SuggestionElements[]>(emptyState);

const loading = ref(false);

const loadingOnClick = ref(false);

const error = ref<boolean>(false);

const activeIndex = ref(0);

const focus = ref(false);

const itemListContainer = ref<HTMLElement>();

const input = ref<HTMLInputElement>();

const placeholderText = computed((): string => (!focus.value ? 'Suchen' : ''));

const baseUrl = computed((): string => store.state.config.baseUrl);

const buildUrl = (urlParams: string) => `${baseUrl.value}${urlParams}?term=${encodeURIComponent(term.value)}`;

const minCharCount = computed((): boolean => !!term.value && term.value.length >= 2);

const onFocus = (): void => {
	focus.value = true;
	input.value?.focus();
};

const onscroll = () => {
	if (window.scrollY >= 240 && toggleDropdown.value && isMinTablet.value) {
		toggleDropdown.value = false;
		input.value?.blur();
	}
};

const onScrollContainter = () => {
	if (!isMobile.value && searchSuggests.value?.length <= 3) return;

	input.value?.blur();
};

const search = async() => {
	loading.value = true;
	try {
		searchSuggests.value = await searchSuggestService.get(term.value);
	} catch (err) {
		console.error(err);
		error.value = true;
	} finally {
		loading.value = false;
	}
};

const bodyElem = computed((): HTMLElement => document && document.body);

watch(() => toggleDropdown.value, () => {
	// needed for ipads to be under the search
	bodyElem.value.classList.toggle('hidden-main-menu', toggleDropdown.value);
});

const clearSuggestAndClose = (): void => {
	term.value = '';
	searchSuggests.value = [];
	toggleDropdown.value = false;
};

const onTermChange = (): void => {
	error.value = false;
	if (!minCharCount.value) {
		searchSuggests.value = emptyState;
		toggleDropdown.value = false;
	} else {
		toggleDropdown.value = true;
		search();
	}
};

const onKeydown = (e: KeyboardEvent): void => {
	if (toggleDropdown.value && e.key === 'Enter') {
		const activeItem = searchSuggestItemList.value[activeIndex.value];
		if (activeItem && activeItem.$el) {
			(activeItem.$el as HTMLElement).click();
		}
	} else if (['Escape'].indexOf(e.key) === 0) {
		toggleDropdown.value = false;
	} else if (e.key === 'Tab' && !e.shiftKey && toggleDropdown.value) {
		onNavigate(1);
	} else if (e.shiftKey && e.key === 'Tab') {
		onNavigate(-1);
	}
};

const onNavigate = (direction: number) => {
	nextTick(() => {
		const list = itemListContainer.value as HTMLElement;

		if (list && searchSuggestItemList.value.length) {
			const itemsArr = searchSuggestItemList.value;
			activeIndex.value = Math.min(Math.max(0, activeIndex.value + direction), itemsArr.length - 1);
			const activeItem = itemsArr[activeIndex.value];
			activeItem.$el.focus();

			const top = activeItem.offsetTop;
			(list as HTMLElement).scrollTo({ top, behavior: 'smooth' });
		}
	});
};

const onInput = (): void => {
	// Important: In Android, we need to handle insertCompositionText for input events, as keyboards send this instead of insertText.
	if (input.value?.value && input.value?.value !== '') {
		activeIndex.value = 0;
		term.value = input.value.value;
	}
	onTermChange();
};

const onFieldClick = (clickOnField: boolean) => {
	if (clickOnField && !toggleDropdown.value && term.value && searchSuggests.value.length) {
		toggleDropdown.value = true;
	}
};

const onOutsideClick = (): void => {
	toggleDropdown.value = false;
};

const removeExistingSpinner = (): void => {
	toggleDropdown.value = false;
	loadingOnClick.value = false;
};

onBeforeMount(() => {
	window.addEventListener('pageshow', removeExistingSpinner);
});

onMounted((): void => {
	formField.value = dropdown.value?.field as any;
	input.value = formField.value?.input as HTMLInputElement;
	headerHamburgerBtn.value = document.querySelector('.header-iconbar__entry-menu');
	headerHamburgerBtn.value?.addEventListener('click', onOutsideClick);

	document.addEventListener('keydown', onKeydown);
	input.value?.addEventListener('input', debounce(onInput, 400));
	document.addEventListener('scroll', onscroll);
});

onBeforeUnmount((): void => {
	window.removeEventListener('pageshow', removeExistingSpinner);
	document.removeEventListener('keydown', onKeydown);
	headerHamburgerBtn.value?.removeEventListener('click', onOutsideClick);
	input.value?.removeEventListener('input', onInput);
	document.removeEventListener('scroll', onscroll);
});

__expose({
	input,
	toggleDropdown,
	dropdown,
	clearSuggestAndClose,
	term,
	search,
	searchSuggests
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "search-suggest",
    onFocusCapture: onFocus
  }, [
    _createVNode(DropdownField, {
      ref_key: "dropdown",
      ref: dropdown,
      modelValue: term.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((term).value = $event)),
      tabindex: "-1",
      class: _normalizeClass(["search-suggest__dropdown", {'search-suggest__dropdown-open' : toggleDropdown.value}]),
      readonly: false,
      manual: toggleDropdown.value,
      "close-on-esc": false,
      "show-footer": false,
      "show-toggle-icon": false,
      placeholder: placeholderText.value,
      icon: _ctx.icon,
      "prevent-mousedown": false,
      "allow-clear": true,
      "is-focused": focus.value,
      "custom-clear-icon": _ctx.customClearIcon,
      "onDropdownField:FieldClick": onFieldClick,
      "onDropdownField:OutsideClick": onOutsideClick,
      "onDropdownField:Focus": onFocus,
      "onDropdownField:Blur": _cache[2] || (_cache[2] = () => focus.value = false),
      "onDropdownField:Clear": clearSuggestAndClose,
      "onDropdownField:Navigate": onNavigate
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          ref_key: "itemListContainer",
          ref: itemListContainer,
          class: "search-suggest__list",
          role: "group",
          "aria-label": "Ergebnisse",
          tabindex: "-1",
          onScroll: onScrollContainter
        }, [
          _createCommentVNode(" Loading "),
          (loading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(Loading, {
                  size: "small",
                  class: "search-suggest__loader"
                })
              ]))
            : (error.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createCommentVNode(" Error "),
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "search-suggest__helper" }, [
                    _createElementVNode("p", null, "Der Server ist im Augenblick nicht erreichbar."),
                    _createTextVNode(" Bitte versuchen Sie es in Kürze erneut. ")
                  ], -1 /* HOISTED */))
                ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
              : (minCharCount.value && !searchSuggests.value.length )
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createCommentVNode(" Empty result "),
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "search-suggest__helper" }, " Keine Ergebnisse gefunden. ", -1 /* HOISTED */))
                  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createCommentVNode(" Result list "),
                    _createElementVNode("ul", {
                      class: _normalizeClass(["search-suggest__item-list", {'search-suggest__item-list--loading': loadingOnClick.value}])
                    }, [
                      (loadingOnClick.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(Loading, {
                              size: "small",
                              class: "search-suggest__loader"
                            })
                          ]))
                        : _createCommentVNode("v-if", true),
                      _createCommentVNode(" Result item "),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchSuggests.value, (item, key) => {
                        return (_openBlock(), _createBlock(SerachSuggestItem, {
                          key: key,
                          ref_for: true,
                          ref_key: "searchSuggestItemList",
                          ref: searchSuggestItemList,
                          class: _normalizeClass({'is-active': key === activeIndex.value}),
                          url: buildUrl(item.url),
                          item: item,
                          "onSearchSuggestItem:Loading": _cache[0] || (_cache[0] = ($event: any) => (loadingOnClick.value = true))
                        }, null, 8 /* PROPS */, ["class", "url", "item"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ], 2 /* CLASS */)
                  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
        ], 544 /* NEED_HYDRATION, NEED_PATCH */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "manual", "placeholder", "icon", "is-focused", "custom-clear-icon", "class"])
  ], 32 /* NEED_HYDRATION */))
}
}

})