import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "travel-types" }
const _hoisted_2 = ["href", "title"]
const _hoisted_3 = { class: "travel-types__item-header" }
const _hoisted_4 = ["data-bg"]
const _hoisted_5 = { class: "travel-types__item-title" }
const _hoisted_6 = { class: "travel-types__item-title-text" }
const _hoisted_7 = { class: "travel-types__item-subtitle" }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import { BubbleData } from '@/interfaces/travel-type-bubble-type';

interface Props {
	items: BubbleData[],
	headerProps: HeaderProps
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TravelTypes',
  props: {
    items: {},
    headerProps: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ComponentHeader, { "header-props": _ctx.headerProps }, null, 8 /* PROPS */, ["header-props"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("a", {
          key: item.title,
          href: item.link,
          class: "travel-types__item",
          title: item.titleAttribute ? item.titleAttribute : item.title
        }, [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", {
              class: "travel-types__item-image rpb_lazy",
              "data-bg": item.image
            }, null, 8 /* PROPS */, _hoisted_4),
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(item.title), 1 /* TEXT */),
              _createVNode(BaseIcon, {
                name: "chevron",
                class: "travel-types__item-icon"
              })
            ])
          ]),
          _createElementVNode("h5", _hoisted_7, _toDisplayString(item.subtitle), 1 /* TEXT */)
        ], 8 /* PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}
}

})