<template>
	<div class="usp rpb_container">
		<ComponentHeader
			:header-props="headerProps"
			:class="'usp__header'"
		/>
		<div class="usp__content-container">
			<div
				v-for="(item, index) in uspData"
				:key="index"
				class="usp__element"
			>
				<component
					:is="item.link && item.link != ''?'a':'div'"
					:href="item.link"
					class="usp__link-wrapper"
					data-ga-event="select_content"
					data-ga-content_type="USP"
					:data-ga-item_id="item.title"
				>
					<div
						class="usp__inner-content"
					>
						<div class="usp__image">
							<img
								v-if="item.image"
								:src="item.image"
								:alt="item.altTextImage"
							>
						</div>
						<div class="usp__inner-content-box">
							<div class="usp__textcontent">
								<h4 v-if="item.title">
									{{ item.title }}
								</h4>
								<p v-if="item.body">
									{{ item.body }}
								</p>
							</div>
							<BaseIcon
								v-if="item.link"
								name="chevronRight"
								class="usp__icon"
							/>
						</div>
					</div>
				</component>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import type { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import type { USPData } from '@/interfaces/usp-data-type';

const props = defineProps<{
	uspData: Array<USPData>,
	title: string,
	subtitle?: string,
	headerLink?: string,
	headerLayout?: string,
}>();

const headerProps: HeaderProps = {
	title: props.title,
	subtitle: props.subtitle,
	mobileTitle: props.title,
	mobileSubtitle: props.subtitle,
	link: props.headerLink
};

</script>

<style lang="scss" scoped>


* {
	margin: 0;
	padding: 0;
}

.usp {
	display: flex;
	flex-flow: column;
	align-content: center;
	justify-content: left;
	text-align: center;
	margin: 2.4rem auto;

	h4 {
		font-size: $font-small-1;
		line-height: 2.2rem;
		color: $color-primary;
		font-weight: $font-weight-bold;
		margin-bottom: 0.4rem;
	}

	p {
		font-size: $font-small-2;
		line-height: 1.9rem;
		font-weight: $font-weight-regular;
		color: $color-black;
	}

	&__header {
		margin-left: 0;
	}

	&__content-container {
		display: flex;
		flex-wrap: wrap;
	}

	&__element {
		display: flex;
		margin-bottom: 1.8rem;
		flex: 100%;
		flex-grow: 0;
		flex-shrink: 0;
	}

	&__link-wrapper {
		display: inline-flex;
		flex-flow: column;
		justify-content: center;
		width: 100%;
	}

	a h4 {
		text-decoration: underline;
	}

	a:hover {
		text-decoration: none;
		cursor: pointer;

		h4 {
			text-decoration: underline;
		}
	}

	&__inner-content {
		display: flex;
		width: 100%;
	}

	&__image {
		display: flex;
		padding: 0 1.6rem;
		height: 8rem;
		margin: auto 0;

		img {
			width: 8rem;
			object-fit: cover;
		}
	}

	&__inner-content-box {
		display: flex;
		flex-flow: row;
		width: 100%;
		padding: 0.8rem 1.6rem 0.8rem 0;
		min-height: 9.6rem;
	}

	&__textcontent {
		display: flex;
		flex-flow: column;
		width: 100%;
		margin: 0 2.6rem 0 0;
		text-align: left;
	}

	&__icon {
		width: 3.5rem;
		height: 3.5rem;
		display: flex;
		fill: $color-primary;
		margin: auto 0 auto auto;
	}

	@media (min-width: $breakpoint-verysmall) {
		&__element {
			flex: 50%;
		}

		&__textcontent {
			margin: 0 4.6rem 0 0;
		}
	}

	@media (min-width: 615px) {
		h4 {
			margin-bottom: 0;
		}

		&__textcontent {
			justify-content: space-between;
		}
	}

	@media (min-width: $breakpoint-small) {
		h4 {
			font-size: $font-medium-2;
			line-height: 2.72rem;
			margin-bottom: 0.8rem;
		}

		&__content-container {
			padding: 0 $padding-normal;
		}

		&__textcontent {
			max-width: 22rem;
		}

		&__link-wrapper {
			max-width: 38rem;
		}

		&__inner-content-box {
			min-height: 11rem;
			padding: 1.6rem 1.6rem 1.6rem 0;
		}

		p {
			font-size: $font-small-1;
			line-height: 2.18rem;
		}

		&__icon {
			margin: auto 0;
		}
	}

	@media (min-width: $breakpoint-medium) {
		h4 {
			font-size: $font-medium-1;
			line-height: 3rem;
			margin-bottom: 0.8rem;
		}

		p {
			font-size: $font-medium-3;
			line-height: 2.45rem;
		}

		&__textcontent {
			max-width: 39.6rem;
		}

		&__link-wrapper {
			max-width: 64.2rem;
		}
	}

	@media (min-width: $breakpoint-verylarge) {
		&__content-container {
			padding: 0;
		}
	}
}
</style>
